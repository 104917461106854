.FooterContainer {
}

.FooterMainContainer {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 20px;
}

.FooterContactDiv {
    text-align: left;
    color: #FFFFFF;
}

.FooterContactDivMobile {
    color: #FFFFFF;
}

.FooterSocialDiv {
    text-align: right;
    color: #FFFFFF;
}
.FooterSocialDivMobile {
    color: #FFFFFF;
    padding-bottom: 10px;
}

.FooterSocialA {
    color: #FFFFFF;
    font-size: 1.5rem;
    padding: 0 10px;
}

.FooterSocialA:hover {
    color: #a6a6a6;
    /*color: #ff9595;*/
}

.FooterContactCopyright {
    font-size: 1rem;
}
